// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PageTransitions.css */
.fade-enter {
  opacity: 0;
  position: absolute;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in 200ms; /* Added transition delay */
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/pageTransitions/pageTransitions.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,uCAAuC,EAAE,2BAA2B;AACtE;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,kCAAkC;AACpC","sourcesContent":["/* PageTransitions.css */\n.fade-enter {\n  opacity: 0;\n  position: absolute;\n}\n.fade-enter-active {\n  opacity: 1;\n  transition: opacity 200ms ease-in 200ms; /* Added transition delay */\n}\n.fade-exit {\n  opacity: 1;\n}\n.fade-exit-active {\n  opacity: 0;\n  transition: opacity 200ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
