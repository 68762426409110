// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderPayment {
    width: 1280px;
    padding-block: 10px;
    margin: 0 auto;
}
.orderPayment .orderItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.orderPayment .orderItems .orderItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 30px;
    width: 95%;
    outline: 1px solid #ccc;
}
.orderPayment .orderItems .orderItem .title {
    text-decoration: none;
    color: rgb(44, 44, 44);
    width: 60%;
}
.orderPayment .orderItems .orderItem img {
    width: 200px;
}
.orderPayment .success h1 {
    padding-left: 50px;
    padding-block: 5px;
    background: linear-gradient(90deg,
    rgb(59, 131, 255) -30%, /* Increase the percentage to have more white */
    rgb(210, 235, 255) 80% /* Blue starts at 70% and goes to 100% */
);
}
.orderPayment .failed h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 50px;
    padding-block: 5px;
    background-color: red;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/success/success.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,oBAAoB;IACpB,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,UAAU;AACd;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB;;;CAGH;AACD;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".orderPayment {\n    width: 1280px;\n    padding-block: 10px;\n    margin: 0 auto;\n}\n.orderPayment .orderItems {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n}\n.orderPayment .orderItems .orderItem {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding-inline: 30px;\n    width: 95%;\n    outline: 1px solid #ccc;\n}\n.orderPayment .orderItems .orderItem .title {\n    text-decoration: none;\n    color: rgb(44, 44, 44);\n    width: 60%;\n}\n.orderPayment .orderItems .orderItem img {\n    width: 200px;\n}\n.orderPayment .success h1 {\n    padding-left: 50px;\n    padding-block: 5px;\n    background: linear-gradient(90deg,\n    rgb(59, 131, 255) -30%, /* Increase the percentage to have more white */\n    rgb(210, 235, 255) 80% /* Blue starts at 70% and goes to 100% */\n);\n}\n.orderPayment .failed h1 {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    padding-left: 50px;\n    padding-block: 5px;\n    background-color: red;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
