// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy {
    width: 1280px;
    height: 100%;
    margin: auto;
}
.policy .container {
    padding-inline: 15px;
}
.policy .container h1 {
    color: #3B83FF;
    text-align: center;
}
.policy .container h2 {
    display: flex;
    gap: 10px;
}
.policy .container div {
    color: #3B83FF;    
}
@media only screen and (max-width: 1280px) {
    .policy {
        width: 100%;
    }
 }
 @media only screen and (max-width: 780px) {
    .policy {
        position: relative;
        padding-top: 1px;
    }
 }
 @media only screen and (max-width: 660px) {
    .policy {
        padding-top: 85.33333px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/policy/policy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,cAAc;AAClB;AACA;IACI;QACI,WAAW;IACf;CACH;CACA;IACG;QACI,kBAAkB;QAClB,gBAAgB;IACpB;CACH;CACA;IACG;QACI,uBAAuB;IAC3B;EACF","sourcesContent":[".policy {\n    width: 1280px;\n    height: 100%;\n    margin: auto;\n}\n.policy .container {\n    padding-inline: 15px;\n}\n.policy .container h1 {\n    color: #3B83FF;\n    text-align: center;\n}\n.policy .container h2 {\n    display: flex;\n    gap: 10px;\n}\n.policy .container div {\n    color: #3B83FF;    \n}\n@media only screen and (max-width: 1280px) {\n    .policy {\n        width: 100%;\n    }\n }\n @media only screen and (max-width: 780px) {\n    .policy {\n        position: relative;\n        padding-top: 1px;\n    }\n }\n @media only screen and (max-width: 660px) {\n    .policy {\n        padding-top: 85.33333px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
