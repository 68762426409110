// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules {
    width: 1280px;
    font-family: Arial, sans-serif;
    margin: auto;
    line-height: 1.6;
    color: #333;
}
.rules h1, h2 {
    text-align: center;
}
.rules h3 {
    color: #2c3e50;
    font-size: 24px;
    border-bottom: 2px solid #2c3e50;
    padding-bottom: 10px;
}
.rules ol {
    padding-left: 20px;
}
.rules ol li {
    margin-bottom: 15px;
}
.rules ol li p {
    background: #eef0f1;
    padding: 10px;
    border-left: 4px solid #2c3e50;
}
.rules a {
    color: #2980b9;
    text-decoration: none;
}
.rules a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/rules/rules.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,gCAAgC;IAChC,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".rules {\n    width: 1280px;\n    font-family: Arial, sans-serif;\n    margin: auto;\n    line-height: 1.6;\n    color: #333;\n}\n.rules h1, h2 {\n    text-align: center;\n}\n.rules h3 {\n    color: #2c3e50;\n    font-size: 24px;\n    border-bottom: 2px solid #2c3e50;\n    padding-bottom: 10px;\n}\n.rules ol {\n    padding-left: 20px;\n}\n.rules ol li {\n    margin-bottom: 15px;\n}\n.rules ol li p {\n    background: #eef0f1;\n    padding: 10px;\n    border-left: 4px solid #2c3e50;\n}\n.rules a {\n    color: #2980b9;\n    text-decoration: none;\n}\n.rules a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
