// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    display: flex;
    background-color: black;
    color: white;
    text-align: center;
    padding-block: 50px;
    transition: 0.3s;
    z-index: -4;
}
footer .container {
    display: flex;
    justify-content: space-around;
    width: 1280px;
    margin: auto;
}
footer a {
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,aAAa;IACb,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":["footer {\n    display: flex;\n    background-color: black;\n    color: white;\n    text-align: center;\n    padding-block: 50px;\n    transition: 0.3s;\n    z-index: -4;\n}\nfooter .container {\n    display: flex;\n    justify-content: space-around;\n    width: 1280px;\n    margin: auto;\n}\nfooter a {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
